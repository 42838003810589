var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"600","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-badge',{attrs:{"content":_vm.notificationCount,"color":"blue","overlap":""}},[_c('v-btn',_vm._g({attrs:{"disabled":_vm.notificationCount === 0,"text":"","color":"info"}},on),[_vm._v(_vm._s(_vm.buttonText))])],1)]}}]),model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_c('v-card',[_c('v-card-title',[_vm._v("Sending Notifications")]),_c('v-list',_vm._l((_vm.notices),function(ref){
var id = ref.id;
var title = ref.title;
var total = ref.total;
var sent = ref.sent;
return _c('v-list-item',{key:'notice-' + id,attrs:{"disabled":total === 0}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(title))]),(total === 0)?_c('v-list-item-subtitle',[_vm._v("**No notifications to send**")]):(_vm.running)?_c('v-list-item-subtitle',[_vm._v("Sent "+_vm._s(sent)+" out of "+_vm._s(total)+" notification"+_vm._s(total === 1 ? '' : 's'))]):_c('v-list-item-subtitle',[_vm._v("Ready to send "+_vm._s(total)+" notification"+_vm._s(total === 1 ? '' : 's'))])],1)],1)}),1),(_vm.complete)?_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.complete = false; _vm.active = false}}},[_vm._v("Done")])],1):(!_vm.running)?_c('v-card-actions',[_c('v-btn',{attrs:{"color":"success","text":""},on:{"click":_vm.sendNotifications}},[_vm._v("Send Notifications")]),_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.active = false}}},[_vm._v("Cancel")])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }