<template>
  <v-dialog v-model="active" width="600" persistent>
    <template v-slot:activator="{ on }">
      <v-badge :content="notificationCount" color="blue" overlap>
        <v-btn v-on="on" :disabled="notificationCount === 0" text color="info">{{ buttonText }}</v-btn>
      </v-badge>
    </template>
    <v-card>
      <v-card-title>Sending Notifications</v-card-title>
      <v-list>
        <v-list-item v-for="{ id, title, total, sent } of notices" :key="'notice-' + id" :disabled="total === 0">
          <v-list-item-content>
            <v-list-item-title>{{ title }}</v-list-item-title>
            <v-list-item-subtitle v-if="total === 0">**No notifications to send**</v-list-item-subtitle>
            <v-list-item-subtitle v-else-if="running">Sent {{ sent }} out of {{ total }} notification{{ total === 1 ? '' : 's' }}</v-list-item-subtitle>
            <v-list-item-subtitle v-else>Ready to send {{ total }} notification{{ total === 1 ? '' : 's' }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-card-actions v-if="complete">
        <v-btn text @click="complete = false; active = false">Done</v-btn>
      </v-card-actions>
      <v-card-actions v-else-if="!running">
        <v-btn color="success" text @click="sendNotifications">Send Notifications</v-btn>
        <v-spacer></v-spacer>
        <v-btn text @click="active = false">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { computed, ref, watch } from '@vue/composition-api'
export default {
  props: {
    noticeIds: {
      type: Array,
      default: () => {
        return []
      }
    },
    buttonText: {
      type: String,
      default: 'Send Notification Now'
    },
    notification: {
      type: Object
    }
  },
  setup (props, { root }) {
    const active = ref(false)
    const notices = ref([])
    const running = ref(false)
    const complete = ref(false)

    watch(() => props.noticeIds, async () => {
      notices.value = []
      for (const id of props.noticeIds) {
        const data = await root.$feathers.service('mailroom/notification').get(id)
        const query = await root.$store.dispatch('mailroom/buildNotificationQuery', data)
        const { total } = await root.$feathers.service('mailroom/package').find({ query })
        notices.value.push({ id, title: data.title, total, sent: 0 })
      }
    })
    watch(() => props.notification, async () => {
      if (props.notification) {
        const { _id: id, select, title } = props.notification
        const query = await root.$store.dispatch('mailroom/buildNotificationQuery', { select })
        const { total } = await root.$feathers.service('mailroom/package').find({ query })
        notices.value = [{ id, title, total, sent: 0 }]
      }
    })
    const notificationCount = computed(() => {
      let count = 0
      if (notices.value.length > 0) {
        notices.value.forEach(({ total }) => { count += total })
      }
      return count
    })

    async function sendNotifications () {
      running.value = true
      for (let i = 0; i < notices.value.length; i++) {
        const { id } = notices.value[i]
        const { select, email, text, after } = await root.$feathers.service('mailroom/notification').get(id)
        const query = await root.$store.dispatch('mailroom/buildNotificationQuery', { select })
        const { total } = await root.$feathers.service('mailroom/package').find({ query })
        notices.value.splice(i, 1, { ...notices.value[i], total })
        const users = await root.$store.dispatch('mailroom/getNotificationUsers', { select })
        for (const pidm in users) {
          const ids = users[pidm]
          await root.$store.dispatch('mailroom/sendNotification', { notificationId: id, pidm, ids, email, text, after })
          notices.value.splice(i, 1, { ...notices.value[i], sent: notices.value[i].sent + ids.length })
          await wait(400)
        }
        // Patch the notification to show when it was last sent
        if (total > 0) {
          const lastSent = new Date()
          await root.$feathers.service('mailroom/notification').patch(id, { lastSent })
        }
      }
      complete.value = true
      running.value = false
    }

    async function refresh () {
      if (props.noticeIds && props.noticeIds.length > 0 && running.value === false) {
        for (const id of props.noticeIds) {
          const data = await root.$feathers.service('mailroom/notification').get(id)
          const query = await root.$store.dispatch('mailroom/buildNotificationQuery', data)
          const { total } = await root.$feathers.service('mailroom/package').find({ query })
          for (let i = 0; i < notices.value.length; i++) {
            if (notices.value[i].id === id) notices.value.splice(i, 1, { ...notices.value[i], total })
          }
        }
      }
    }

    function wait (milliseconds) {
      return new Promise(resolve => {
        setTimeout(resolve, milliseconds)
      })
    }

    return {
      active,
      notices,
      running,
      complete,
      notificationCount,
      sendNotifications,
      refresh
    }
  }
}
</script>
